import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import theme from "../components/theme"

import styled, {ThemeProvider} from "styled-components"

const Container = styled.div`
  display: grid;
  grid: 1fr 1fr / repeat(6, 1fr) ;
  grid-gap: 10px;
  background: #101111;
  height : 100vh;
`

const Box= styled.div`
  background: #373142;
  color: ${props => props.theme.fontcolor};
  text-align: center;
  line-height: 100px;
  margin: 20px;
  //border: solid 1px red;
  &:nth-child(1) {
    grid-column: -2/-1;
    grid-row: 1/span 3;
  }
  &:nth-child(8) {
    grid-column: 1/-2;
    grid-row: 1;
  }
  &:nth-child(7) {
    grid-column: 1;
    grid-row: 1/ span 2;
    z-index: 5;
    background: #514761; 
  }
`

Box.defaultProps = {
  theme: {
    fontcolor: "palevioletred"
  }
}


const IndexPage = ({location}) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <ThemeProvider theme={theme}>
    <Container>
    <Box>1</Box>
    <Box>2</Box>
    <Box>3</Box>
    <Box>4</Box>
    <Box>5</Box>
    <Box>6</Box>
    <Box>7</Box>
    <Box>8</Box>
    </Container>
    </ThemeProvider>
    <div style={{ maxWidth: `3000px`, marginBottom: `1.45rem` }}>
    </div>
  </Layout>
)

export default IndexPage
